import { Text } from 'wix-ui-tpa/Text';
import { classes, st } from './EmptyState.st.css';
import { AccessibilityHtmlTags } from '../../../../utils/AccessibilityHtmlTags.const';
import React from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

export interface EmptyStateProps {
  title: string;
  subtitle: string;
}

const EmptyState: React.FC<EmptyStateProps> = ({
  title,
  subtitle,
}: EmptyStateProps) => {
  const { isMobile, isRTL } = useEnvironment();

  return (
    <div
      data-hook="empty-state"
      className={st(classes.wrapper, {
        isMobile,
        isRTL,
      })}
    >
      <Text
        data-hook="empty-state-title"
        className={classes.title}
        tagName={AccessibilityHtmlTags.SecondaryHeading}
      >
        {title}
      </Text>
      <Text
        data-hook="empty-state-subtitle"
        className={st(classes.subtitle, {
          isMobile,
          isRTL,
        })}
        tagName={AccessibilityHtmlTags.Paragraph}
      >
        {subtitle}
      </Text>
    </div>
  );
};

export default EmptyState;
